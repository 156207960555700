import { CustomError, createUuid } from '@common/utils';
import { infiniteQueryOptions, queryOptions } from '@tanstack/react-query';

import { AUTH_API, UNAUTH_API } from '@/api';
import { queryKeys } from '@/constants/query-keys';

import { EXCEPTED_HIDDEN_CODE } from './filter';

interface SituationOptionsProps {
  isLogin: boolean | undefined;
  situationId: number;
}

export const situationQueryKeys = {
  hospitalList: createUuid(),
  hospitalDetailLive: createUuid(),
  hospitalUserAction: createUuid(),
  medicalPolicyInfinityList: createUuid(),
  emergencyRoomCountOfRegion: createUuid(),
};

export const situationQueryOptions = {
  list: ({ isLogin, situationId }: SituationOptionsProps) =>
    queryOptions({
      queryKey: [
        queryKeys.situationPolicyList,
        queryKeys.like,
        queryKeys.bookmark,
        situationId,
        isLogin,
      ],
      queryFn: () => {
        return isLogin
          ? AUTH_API.getSituationPolicyList({ situationId })
          : UNAUTH_API.getSituationPolicyList({ situationId });
      },
      enabled: isLogin !== undefined,
      select: ({ context }) => {
        return {
          id: context?.situation?.situation_id,
          title: context?.situation?.name,
          contents: context?.situation?.description ?? '',
          thumbnail: context?.situation?.file_url,
          policyCount: context?.situation?.policy_count ?? 0,
          policyList:
            context?.situation_list?.map((policy) => ({
              id: policy.id,
              idIdx: policy.meta_policy_id_idx,
              policyName: policy.policy_name,
              dday: policy.dday,
              agencyLogo: policy.agency_logo,
              agency: policy.agency,
              isThumbUp: policy.like_yn,
              dueDateEnd: policy.expiration_date,
              supportBenefit: policy.support_benefit,
              provisionTypeListStr: policy.provision_type_str_list,
              likeCount: policy.like_count,
              commentCount: policy.comment_count,
              isWishlist: policy.wishlist_yn,
            })) ?? [],
        };
      },
    }),

  hospitalInfinityList: ({
    page,
    size,
    time,
    ...restParams
  }: Parameters<typeof UNAUTH_API.getEmergencyRoomList>[number]) =>
    infiniteQueryOptions({
      queryKey: [situationQueryKeys.hospitalList, page, size, time, restParams],
      queryFn: ({ pageParam }) =>
        UNAUTH_API.getEmergencyRoomList({
          page: pageParam,
          size,
          time: time === 24 ? 0 : time,
          ...restParams,
        }),
      getNextPageParam: (lastPage) => {
        const { page: currentPage, has_next } = lastPage.context ?? {};

        if (!has_next || !currentPage) return;

        return currentPage + 1;
      },
      initialPageParam: page,
    }),

  HospitalList: ({
    time,
    ...restParams
  }: Omit<
    Parameters<typeof UNAUTH_API.getEmergencyRoomList>[number],
    'page' | 'size'
  >) => {
    return queryOptions({
      queryKey: [situationQueryKeys.hospitalList, restParams, time],
      queryFn: () =>
        UNAUTH_API.getEmergencyRoomList({
          ...restParams,
          time: time === 24 ? 0 : time,
          page: 1,
          size: 999,
        }),
    });
  },

  hospitalDetailLive: (emergencyRoomId: number) =>
    queryOptions({
      refetchInterval: 60_000,
      queryKey: [situationQueryKeys.hospitalDetailLive, emergencyRoomId],
      queryFn: () =>
        UNAUTH_API.getEmergencyRoomLive({
          emergencyRoomId,
        }),
      select: ({ context }) => ({
        roomCount: context?.hv36,
        babyRoomCount: context?.hv37,
        ambulanceUsable: context?.hvamyn,
        ctUsable: context?.hvctayn,
        mriUsable: context?.hvmriayn,
        doctorName: context?.hvdnm,
      }),
    }),

  hospitalBookmark: (emergencyRoomId: number, isLogin: boolean) =>
    queryOptions({
      queryKey: [
        situationQueryKeys.hospitalUserAction,
        queryKeys.bookmark,
        emergencyRoomId,
      ],
      queryFn: () => AUTH_API.getEmergencyRoomDetail({ emergencyRoomId }),
      select: ({ context }) => ({
        isBookmarked: context?.wishlist_yn,
      }),
      enabled: isLogin,
    }),

  medicalPolicyInfinityList: ({
    page,
    size,
    isLogin,
    ...restParams
  }: Parameters<typeof UNAUTH_API.getMedicalPolicyList>[number] & {
    isLogin?: boolean;
  }) =>
    infiniteQueryOptions({
      queryKey: [
        queryKeys.like,
        situationQueryKeys.medicalPolicyInfinityList,
        page,
        size,
        restParams,
        isLogin,
      ],
      queryFn: ({ pageParam }) => {
        const params = {
          page: pageParam,
          size,
          ...restParams,
        };

        return isLogin
          ? AUTH_API.getMedicalPolicyList(params)
          : UNAUTH_API.getMedicalPolicyList(params);
      },
      getNextPageParam: (lastPage) => {
        const { page: currentPage, total_count } = lastPage.context ?? {};

        if (!total_count || !currentPage)
          throw new CustomError({
            return_message: '페이지 수 데이터가 없습니다.',
          });

        const hasNextPage = currentPage < total_count / size;

        if (!hasNextPage) return;

        return currentPage + 1;
      },
      initialPageParam: page,
      enabled: isLogin !== undefined,
    }),
  emergencyRoomCountOfRegion: ({
    time,
    ...restParams
  }: Parameters<typeof UNAUTH_API.getEmergencyRoomCountOfRegion>[number]) =>
    queryOptions({
      queryFn: () =>
        UNAUTH_API.getEmergencyRoomCountOfRegion({
          ...restParams,
          time: time === 24 ? 0 : time,
        }),
      queryKey: [
        situationQueryKeys.emergencyRoomCountOfRegion,
        time,
        restParams,
      ],
      select: ({ context }) => {
        const sejongIndex = context?.contents.find(
          ({ code_sub_region }) =>
            code_sub_region === EXCEPTED_HIDDEN_CODE.REGION_SEJONG_ALL,
        );

        if (sejongIndex) {
          sejongIndex.sub_region = '세종시';
        }

        return context?.contents;
      },
    }),
};
