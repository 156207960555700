import { assert, createUuid } from '@common/utils';
import { queryOptions } from '@tanstack/react-query';
import ky from 'ky';

import { DPG_API, AUTH_API } from '@/api';

interface DeptReportDetailProps {
  custCi: string;
  rprYr: string;
  dbtrRprClssCd: string;
  dbtrRprDt: string;
}

export const dpgQueryOptionsKeys = {
  checkEligibility: createUuid(),
  agreement: createUuid(),
  notice: createUuid(),
  loanInterest: createUuid(),
  deptReportList: createUuid(),
  deptReportDetail: createUuid(),
  getCIInfo: createUuid(),
  address: createUuid(),
};

export const dpgQueryOptions = {
  checkEligibility: (ciToken: string) =>
    queryOptions({
      queryKey: [dpgQueryOptionsKeys.checkEligibility, ciToken],
      queryFn: () =>
        DPG_API.postEligibilityCheck({
          requestBody: {
            custCi: ciToken,
          },
        }),
      retry: false,
      enabled: ciToken !== '',
      select: ({ context }) => {
        return {
          ciToken: context?.custCi,
          isTarget: context?.canRprYn === 'Y',
          isPeriodicReport: context?.preRgrRprtYn === 'Y',
        };
      },
    }),
  agreement: () =>
    queryOptions({
      queryKey: [dpgQueryOptionsKeys.agreement],
      queryFn: DPG_API.getAgreement,
      select: ({ context }) => {
        assert(context, 'context is not valid');

        return {
          financialInfo: context.finInfoAgmtCten,
          personalInfo: context.indvInfoAgmtCten,
        };
      },
    }),
  notice: (year = 2024) =>
    queryOptions({
      queryKey: [dpgQueryOptionsKeys.notice, year, DPG_API],
      queryFn: () =>
        ky
          .get('https://stage.dpgapi.wello.im/wello/v1/dept-reporting/notice', {
            searchParams: { baseYear: year.toString() },
          })
          .json<Awaited<ReturnType<typeof DPG_API.getNotice>>>(),
      select: (data) => {
        assert(data.context, 'context is not valid');

        return {
          content: data.context.noticeCten,
        };
      },
    }),
  loanInterest: (custCi: string) =>
    queryOptions({
      queryKey: [dpgQueryOptionsKeys.loanInterest, custCi],
      queryFn: () =>
        DPG_API.getLoanInterestList({
          requestBody: {
            custCi,
            baseYear: new Date().getFullYear().toString(),
          },
        }),
      enabled: !!custCi,
      select: ({ context }) => {
        assert(context, 'context is not valid');

        return {
          custCi: context.custCi,
          basDt: context.basDt,
          loanAmtSum: context.loanAmtSum,
          loanBlmtSum: context.loanBlmtSum,
          loanIntAmtSum: context.loanIntAmtSum,
          nyRcpDutyApmtAmt: context.nyRcpDutyApmtAmt,
          nyRcpOvdAmt: context.nyRcpOvdAmt,
          nyRcpOvdAdAmt: context.nyRcpOvdAdAmt,
          nyRcpAmtSum: context.nyRcpAmtSum,
          loanList: context.loanList,
        };
      },
    }),
  deptReportList: (custCi: string) =>
    queryOptions({
      queryKey: [dpgQueryOptionsKeys.deptReportList, custCi],
      queryFn: () =>
        DPG_API.getReportHistoryList({
          requestBody: {
            custCi,
          },
        }),
      enabled: !!custCi,
      select: ({ context }) => {
        assert(context, 'context is not valid');

        return {
          contents: context.contents,
          total: context.totalCount,
        };
      },
    }),
  deptReportDetail: ({
    custCi,
    rprYr,
    dbtrRprClssCd,
    dbtrRprDt,
  }: DeptReportDetailProps) =>
    queryOptions({
      queryKey: [
        dpgQueryOptionsKeys.deptReportDetail,
        custCi,
        dbtrRprClssCd,
        dbtrRprDt,
        rprYr,
      ],
      queryFn: () =>
        DPG_API.getReportHistory({
          requestBody: {
            custCi,
            rprYr,
            dbtrRprClssCd,
            dbtrRprDt,
          },
        }),
      enabled: !!custCi,
      select: ({ context }) => {
        assert(context, 'context is not valid');

        return {
          custCi: context.custCi,
          rprFltNm: context.rprFltNm,
          dbtrRprClssCd: context.dbtrRprClssCd,
          rprDutyAbtnYr: context.rprDutyAbtnYr,
          dbtrRprDt: context.dbtrRprDt,
          univGrdtYn: context.univGrdtYn,
          grdtDt: context.grdtDt,
          grdtUnivCd: context.grdtUnivCd,
          grdtUnivNm: context.grdtUnivNm,
          ovseRsdYn: context.ovseRsdYn,
          rsdcPno: context.rsdcPno,
          rsdcPnoAddr: context.rsdcPnoAddr,
          rsdcDtalAddr: context.rsdcDtalAddr,
          rsdcStsgLtnoDivCd: context.rsdcStsgLtnoDivCd,
          ctyCd: context.ctyCd,
          ctyNm: context.ctyNm,
          etcMttCten: context.etcMttCten,
          mblTno: context.mblTno,
          emailAddr: context.emailAddr,
          wkplEan: context.wkplEan,
          spoEan: context.spoEan,
          spoFrnrYn: context.spoFrnrYn,
          spoNm: context.spoNm,
          spoOvseRsdYn: context.spoOvseRsdYn,
          spoRsdcPno: context.spoRsdcPno,
          spoRsdcPnoAddr: context.spoRsdcPnoAddr,
          spoRsdcDtalAddr: context.spoRsdcDtalAddr,
          spoRsdcStsgLtnoDivCd: context.spoRsdcStsgLtnoDivCd,
          spoCtyCd: context.spoCtyCd,
          spoCtyNm: context.spoCtyNm,
          spoWkplEan: context.spoWkplEan,
          indvInfoOferAgmtYn: context.indvInfoOferAgmtYn,
          finInfoOferAgmtYn: context.finInfoOferAgmtYn,
          dbtrNrgrRprClssCd: context.dbtrNrgrRprClssCd,
          careerList: context.careerList,
          spoCareerList: context.spoCareerList,
        };
      },
    }),
  getCIInfo: (accessToken: string | null) =>
    queryOptions({
      queryKey: [dpgQueryOptionsKeys.getCIInfo],
      queryFn: AUTH_API.getMemberVerification,
      enabled: !!accessToken,
      select: ({ context }) => {
        assert(context, 'context is not valid');

        return {
          ci: context.ci,
        };
      },
    }),
  address: (custCi: string) =>
    queryOptions({
      queryKey: [dpgQueryOptionsKeys.address, custCi],
      queryFn: () => DPG_API.getAddress({ requestBody: { custCi } }),
      select: ({ context }) => {
        assert(context, 'context is not valid');

        return {
          address: context.pnoAddr,
          addressDetail: context.dtalAddr,
          zonecode: context.pno,
          roadnameCode: context.stsgLtnoDivCd,
        };
      },
    }),
};
