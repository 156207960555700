import { assert, createUuid } from '@common/utils';
import { queryOptions } from '@tanstack/react-query';
import ky from 'ky';

import { UNAUTH_API } from '@/api';

export const metaCodeQueryOptionsKeys = {
  regionList: createUuid(),
  subRegionList: createUuid(),
  geolocation: createUuid(),
  geoCodingByRegionCode: createUuid(),
};

export const metaCodeQueryOptions = {
  regionList: queryOptions({
    queryKey: [metaCodeQueryOptionsKeys.regionList],
    queryFn: () => UNAUTH_API.getMetaCode({ metaCode: 'C01' }),
    select: (data) =>
      data.context?.meta_code_all_list?.[0].meta_sub_code_list?.map((item) => ({
        label: item.value,
        value: item.code,
      })),
  }),

  subRegionList: (regionCode?: string) =>
    queryOptions({
      queryKey: [metaCodeQueryOptionsKeys.subRegionList, regionCode],
      queryFn: () => {
        assert(regionCode, '지역코드가 없습니다.');

        return UNAUTH_API.getMetaCode({ metaCode: regionCode });
      },
      enabled: !!regionCode,
      select: ({ context }) => {
        const [{ meta_sub_code_list }] = context?.meta_code_all_list ?? [];

        const allItem = meta_sub_code_list?.find((item) =>
          item.value.includes('전체'),
        );

        const options = meta_sub_code_list?.filter(
          (item) => !item.value.includes('전체'),
        );

        if (allItem && options) {
          options.unshift(allItem);
        }

        return options?.map(({ value, code }) => ({
          label: value,
          value: code,
        }));
      },
    }),

  geolocation: queryOptions({
    queryKey: [metaCodeQueryOptionsKeys.geolocation],
    queryFn: async () => {
      const ip = await ky.get('https://api.ip.pe.kr').text();

      return UNAUTH_API.getGeolocation({ ip });
    },
    select: ({ context }) => context,
  }),

  geocodingByCode: ({
    regionCode,
    subRegionCode,
  }: Partial<Parameters<typeof UNAUTH_API.getGeocodingByRegionCode>[number]>) =>
    queryOptions({
      queryKey: [
        metaCodeQueryOptionsKeys.geoCodingByRegionCode,
        regionCode,
        subRegionCode,
      ],
      enabled: !!regionCode && !!subRegionCode,
      queryFn: () => {
        assert(regionCode, '지역코드가 없습니다.');
        assert(subRegionCode, '지역코드가 없습니다.');

        return UNAUTH_API.getGeocodingByRegionCode({
          regionCode,
          subRegionCode,
        });
      },
    }),
};
