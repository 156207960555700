import type { MyInfo } from '@/stores/AuthStore';

import { CustomError, createUuid, isEnumValue } from '@common/utils';
import { queryOptions } from '@tanstack/react-query';
import { pick } from 'es-toolkit';
import ky from 'ky';

import { AUTH_API, LOGIN_TYPE, MEMBER_JOIN_STATUS, UNAUTH_API } from '@/api';
import { API_ROUTES, ENV } from '@/constants';

export const authQueryOptionsKeys = {
  myInfo: createUuid(),
  kbLoginTypeList: createUuid(),
  unifiedAccount: createUuid(),
  socialLogin: createUuid(),
  accessToken: createUuid(),
};

interface MyInfoProps {
  accessToken: string | null;
}

interface KbLoginTypeListProps {
  externalMemberId: string | null;
}

interface SocialLoginProps {
  state?: string | null;
  code?: string | null;
}

export interface SocialLoginResult {
  socialLoginToken: string;
  loginType: LOGIN_TYPE;
}

interface LoginResponse {
  access_token: string;
}

export const authQueryOptions = {
  checkAccessTokenStatus: (token: string | null | undefined) =>
    queryOptions({
      queryKey: [token],
      enabled: !!token,
      refetchInterval: 30_000,
      retryDelay: 1_000,
      queryFn: () => {
        if (!token)
          throw new CustomError({ return_message: '토큰이 없습니다.' });

        return UNAUTH_API.checkAccessTokenStatus({
          requestBody: {
            access_token: token,
          },
        });
      },
      select: (data) => data.context?.usable_access_token ?? false,
    }),
  myInfo: ({ accessToken }: MyInfoProps) =>
    queryOptions({
      queryKey: [authQueryOptionsKeys.myInfo, accessToken],
      queryFn: AUTH_API.getMyInfo,
      enabled: !!accessToken,
      select: ({ context }) => {
        const { member_status_cd, save_status_cd } = context ?? {};

        let memberJoinStatus =
          save_status_cd === 'WELLO_MEMBER_FILTER_SAVE_STATUS_WEAKNESS'
            ? MEMBER_JOIN_STATUS.COMPLETE
            : save_status_cd;

        if (
          memberJoinStatus === MEMBER_JOIN_STATUS.COMPLETE &&
          member_status_cd === MEMBER_JOIN_STATUS.NO_PHONE
        ) {
          memberJoinStatus = MEMBER_JOIN_STATUS.NO_PHONE;
        }

        if (!isEnumValue(MEMBER_JOIN_STATUS, memberJoinStatus))
          throw new Error('save_status_cd가 유효하지 않은 값입니다.');

        if (!context) {
          throw new CustomError({ return_message: 'context가 필요합니다.' });
        }

        return {
          ...pick(context, ['email', 'age', 'gender', 'region', 'code_gender']),
          nickName: context?.nick_name,
          id: context?.member_oauth_id,
          isVerification: context?.verification_completed_yn,
          profileImage: context?.profile_image,
          birthDate: context?.birth_date,
          memberCode: context?.member_code,
          subRegion: context?.sub_region,
          codeRegion: context?.code_region,
          codeSubRegion: context?.code_sub_region,
          cellPhone: context?.cell_phone,
          memberJoinStatus,
          memberOauth: context?.member_oauth_id,
          marketingMessageReceiverUpdateAt:
            context?.marketing_message_receiver_update_at,
          notificationOptions: pick(context, [
            'best_contents_receive_yn',
            'hometown_news_receive_yn',
            'community_yn',
            'like_comment_yn',
            'policy_apply_message_receive_yn',
            'marketing_message_receive_yn',
            'popular_policy_receive_yn',
            'reply_comment_yn',
            'report_comment_yn',
            'suggest_policy_receive_yn',
            'marketing_message_mobile_receive_yn',
            'marketing_message_app_push_receive_yn',
            'marketing_message_email_receive_yn',
          ]),
        } satisfies MyInfo;
      },
    }),

  kbLoginTypeList: ({ externalMemberId }: KbLoginTypeListProps) =>
    queryOptions({
      queryKey: [authQueryOptionsKeys.kbLoginTypeList, externalMemberId],
      queryFn: () => {
        if (!externalMemberId)
          throw new CustomError({
            return_message: 'kbId가 없습니다.',
          });

        return UNAUTH_API.getKbMemberLoginType({
          externalMemberId,
        });
      },
      enabled: !!externalMemberId,
      select: (data) => data.context?.login_type_list,
    }),

  unifiedAccount: ({
    loginType,
    socialLoginToken,
  }: Partial<SocialLoginResult>) => {
    const enabled = !!(loginType && socialLoginToken);

    return queryOptions({
      queryKey: [
        authQueryOptionsKeys.unifiedAccount,
        enabled,
        socialLoginToken,
        loginType,
      ],
      staleTime: 0,
      enabled,
      queryFn: () => {
        if (!enabled)
          throw new CustomError({
            return_message: 'socialLoginResult이 없습니다.',
          });

        return UNAUTH_API.getUnifiedAccount({
          requestBody: {
            access_token: socialLoginToken,
            login_type: loginType,
          },
        });
      },
      select: ({ context }) => ({
        phoneNumber: context?.cell_phone,
        accountCount: context?.total_count,
        accountDataList: context?.contents.map((content) => ({
          email: content.email,
          lastLoginedAt: content.last_login,
          loginType: content.login_type_cd,
        })),
      }),
    });
  },

  socialLogin: ({ code, state }: SocialLoginProps) => {
    const enabled = !!(code && state);

    return queryOptions({
      queryKey: [authQueryOptionsKeys.socialLogin, enabled, state],
      staleTime: 0,
      enabled,
      queryFn: async (): Promise<SocialLoginResult> => {
        if (!enabled)
          throw new CustomError({
            return_message: 'socialLoginProps 가 없습니다.',
          });

        const decodedState = window.atob(state);

        const { type, redirectPath } = JSON.parse(decodedState);

        switch (type) {
          //! ⚠️ Kakao는 client secret이 없어서 front에서 직접 요청 가능
          case 'kakao': {
            const { access_token } = await ky
              .post('https://kauth.kakao.com/oauth/token', {
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                  code,
                  state,
                  redirect_uri: `${ENV.NEXT_PUBLIC_DOMAIN}${redirectPath}`,
                  grant_type: 'authorization_code',
                  client_id: ENV.NEXT_PUBLIC_KAKAO_CLIENT_ID,
                }),
              })
              .json<LoginResponse>();

            return {
              loginType: LOGIN_TYPE.KAKAO,
              socialLoginToken: access_token,
            };
          }

          case 'naver': {
            const { access_token } = await ky
              .post(API_ROUTES.NAVER_AUTH.pathname, {
                json: {
                  code,
                  state,
                },
              })
              .json<LoginResponse>();

            return {
              loginType: LOGIN_TYPE.NAVER,
              socialLoginToken: access_token,
            };
          }

          case 'google': {
            const { access_token } = await ky
              .post(API_ROUTES.GOOGLE_AUTH.pathname, {
                json: {
                  code,
                  state,
                },
              })
              .json<LoginResponse>();

            return {
              loginType: LOGIN_TYPE.GOOGLE,
              socialLoginToken: access_token,
            };
          }

          case 'apple': {
            const { id_token } = await ky
              .post(API_ROUTES.APPLE_TOKEN.pathname, {
                json: {
                  code,
                  state,
                },
              })
              .json<{ id_token: string }>();

            return {
              loginType: LOGIN_TYPE.APPLE,
              socialLoginToken: id_token,
            };
          }

          default:
            throw new Error('잘못된 로그인 타입입니다.');
        }
      },
    });
  },
  accessToken: ({
    loginType,
    socialLoginToken,
  }: Partial<SocialLoginResult>) => {
    const enabled = !!(loginType && socialLoginToken);

    return queryOptions({
      queryKey: [
        authQueryOptionsKeys.accessToken,
        enabled,
        socialLoginToken,
        loginType,
      ],
      enabled,
      retry: false,
      queryFn: () => {
        if (!enabled)
          throw new CustomError({
            return_message: 'socialLoginResult이 없습니다.',
          });

        return UNAUTH_API.getAccessToken({
          requestBody: {
            access_token: socialLoginToken,
            login_type: loginType,
          },
        });
      },
      select: ({ context }) => ({
        accessToken: context?.access_token ?? null,
        refreshToken: context?.refresh_token ?? null,
      }),
    });
  },
};
