import { CustomError, createUuid, isEnumValue } from '@common/utils';
import { queryOptions } from '@tanstack/react-query';

import { AUTH_API, UNAUTH_API, WELLO_POLICY_CODE_TYPE } from '@/api';

export enum EXCEPTED_CODE {
  REGION = 'C01',
  GENDER = 'C02',
  UNEMPLOYMENT = 'C04-05',
  TARGET_CHARACTERISTICS = 'C12',
}

export enum EXCEPTED_HIDDEN_CODE {
  //!세종특별자치시 전체
  REGION_SEJONG_ALL = 'C01-08-99',
  //!대상 특성 해당 없음
  TARGET_CHARACTERISTICS_EMPTY = 'C12-99',
}

export const filterQueryOptionsKeys = {
  policyFitMetaData: createUuid(),
  policyFitBabyMetaData: createUuid(),
  interestPolicy: createUuid(),
  kbPolicyFilter: createUuid(),
};

export type WelloPolicyMetaData = Exclude<
  Exclude<
    Awaited<ReturnType<typeof UNAUTH_API.getWelloPolicyCodeAllList>>['context'],
    undefined
  >['contents'],
  undefined
>;

export const filterQueryOptions = {
  policyFitMetaData: () =>
    queryOptions({
      queryKey: [filterQueryOptionsKeys.policyFitMetaData],
      queryFn: UNAUTH_API.getWelloPolicyCodeAllList,
      staleTime: Infinity,
      gcTime: Infinity,
      select: ({ context }) => {
        const { contents } = context ?? {};

        if (!contents)
          throw new CustomError({ return_message: '정보가 없습니다.' });

        const boundedMetaData: WelloPolicyMetaData[] = [];

        const genderMetaData: WelloPolicyMetaData = [];

        const regionMetaData: {
          mainRegion: WelloPolicyMetaData;
          subRegion: WelloPolicyMetaData;
        } = {
          mainRegion: [],
          subRegion: [],
        };

        contents.forEach((content) => {
          const { code } = content;
          let { level } = content;

          if (!code || !level) return;

          const isHidden =
            code.includes('99') && !isEnumValue(EXCEPTED_HIDDEN_CODE, code);

          if (isHidden) return;

          if (code?.includes(EXCEPTED_CODE.GENDER)) {
            if (level === 2) genderMetaData.push(content);

            return;
          }

          if (code.startsWith('C14')) level = level - 1;

          if (code?.includes(EXCEPTED_CODE.REGION)) {
            switch (level) {
              case 2:
                return regionMetaData.mainRegion.push(content);
              case 3:
                return regionMetaData.subRegion.push(content);
              default:
                return;
            }
          }

          if (level === 3 && code.startsWith(EXCEPTED_CODE.UNEMPLOYMENT))
            return;

          if (level === 1) return boundedMetaData.push([content]);

          const rootMetaData = boundedMetaData.find(([rootMetaData]) => {
            const rootMetaDataCode = rootMetaData.code;

            return rootMetaDataCode ? code.includes(rootMetaDataCode) : false;
          });

          return rootMetaData?.push(content);
        });

        const boundedCustomPolicyMetaData: WelloPolicyMetaData[] = [];
        const boundedInterestMetaData = boundedMetaData.filter(
          (metaDataList) => {
            const [rootMetaData] = metaDataList;

            if (
              rootMetaData.wello_policy_code_type_cd ===
              WELLO_POLICY_CODE_TYPE.INTEREST
            ) {
              return true;
            } else {
              //! 관심 필터는 원래 없어서 여기서 예외처리
              if (!rootMetaData.wello_policy_code_filter_cd) return false;

              boundedCustomPolicyMetaData.push(metaDataList);

              return false;
            }
          },
        );

        return {
          customPolicyMetaDataList: boundedCustomPolicyMetaData.flat(),
          interestedCategoryMetaDataList: boundedInterestMetaData.flat(),
          genderMetaData,
          regionMetaData,
        };
      },
    }),
  policyFitBabyMetaData: () =>
    queryOptions({
      queryKey: [filterQueryOptionsKeys.policyFitBabyMetaData],
      queryFn: UNAUTH_API.getWelloPolicyCodeBabyList,
      staleTime: 900_000,
      select: ({ context }) => {
        const { contents } = context ?? {};

        if (!contents)
          throw new CustomError({ return_message: '정보가 없습니다.' });

        const boundedMetaData: WelloPolicyMetaData[] = [];

        const genderMetaData: WelloPolicyMetaData = [];

        const regionMetaData: {
          mainRegion: WelloPolicyMetaData;
          subRegion: WelloPolicyMetaData;
        } = {
          mainRegion: [],
          subRegion: [],
        };

        contents.forEach((content) => {
          const { code } = content;
          let { level } = content;

          if (!code || !level) return;

          const isHidden =
            code.includes('99') && !isEnumValue(EXCEPTED_HIDDEN_CODE, code);

          if (isHidden) return;

          if (code?.includes(EXCEPTED_CODE.GENDER)) {
            if (level === 2) genderMetaData.push(content);

            return;
          }

          if (code.startsWith('C14')) level = level - 1;

          if (code?.includes(EXCEPTED_CODE.REGION)) {
            switch (level) {
              case 2:
                return regionMetaData.mainRegion.push(content);
              case 3:
                return regionMetaData.subRegion.push(content);
              default:
                return;
            }
          }

          if (level === 3 && code.startsWith(EXCEPTED_CODE.UNEMPLOYMENT))
            return;

          if (level === 1) return boundedMetaData.push([content]);

          const rootMetaData = boundedMetaData.find(([rootMetaData]) => {
            const rootMetaDataCode = rootMetaData.code;

            return rootMetaDataCode ? code.includes(rootMetaDataCode) : false;
          });

          return rootMetaData?.push(content);
        });

        const boundedCustomPolicyMetaData: WelloPolicyMetaData[] = [];
        const boundedInterestMetaData = boundedMetaData.filter(
          (metaDataList) => {
            const [rootMetaData] = metaDataList;

            if (
              rootMetaData.wello_policy_code_type_cd ===
              WELLO_POLICY_CODE_TYPE.INTEREST
            ) {
              return true;
            } else {
              //! 관심 필터는 원래 없어서 여기서 예외처리
              if (!rootMetaData.wello_policy_code_filter_cd) return false;

              boundedCustomPolicyMetaData.push(metaDataList);

              return false;
            }
          },
        );

        return {
          customPolicyMetaDataList: boundedCustomPolicyMetaData.flat(),
          interestedCategoryMetaDataList: boundedInterestMetaData.flat(),
          genderMetaData,
          regionMetaData,
        };
      },
    }),
  interestPolicy: () =>
    queryOptions({
      queryKey: [filterQueryOptionsKeys.interestPolicy],
      queryFn: () => AUTH_API.getInterestedFilterData({}),
      select: ({ context }) => context?.code_list,
    }),

  kbPolicyFilter: () =>
    queryOptions({
      queryKey: [filterQueryOptionsKeys.kbPolicyFilter],
      queryFn: UNAUTH_API.getKbPolicyFilterMetaCode,
      select: ({ context }) => context?.code_list,
    }),
};
