import { createUuid } from '@common/utils';
import {
  infiniteQueryOptions,
  keepPreviousData,
  queryOptions,
} from '@tanstack/react-query';

import { AUTH_API, MEMBER_JOIN_STATUS, UNAUTH_API } from '@/api';
import { getPolicyDetail } from '@/api/getPolicyDetail';
import { queryKeys } from '@/constants/query-keys';

export const policyQueryOptionsKeys = {
  popularPolicy: createUuid(),
  peerGroupPolicy: createUuid(),
  listChildcareRelatedPolicy: createUuid(),
  policySearchByName: createUuid(),
  relatedMetaPolicyList: createUuid(),
  relatedCommunityList: createUuid(),
  feedback: createUuid(),
  policyPick: createUuid(),
  policyDetail: createUuid(),
};

interface InterestOptionsProps {
  provisionType?: string;
}

interface PolicyOptionsProps {
  id: number;
  isLogin: boolean | undefined;
}

interface PolicyPeerGroupOptions {
  isLogin: boolean | undefined;
  memberJoinStatus: MEMBER_JOIN_STATUS | undefined;
}

interface SearchOptionsProps {
  codeRegion?: string;
  codeSubRegion?: string;
  keyword?: string;
  isLogin?: boolean | undefined;
  descAgeList?: string[];
  codeProvisionTypeList?: string[];
  policySort?: 'LATEST' | 'POPULARITY';
}

interface RelatedMetaPolicyListProps {
  isLogin: boolean | undefined;
  metaPolicyIdIdx: string;
}

interface ListChildcareRelatedPolicyProps {
  subRegionCode: string;
  isLogin: boolean | undefined;
  isOnlyLogin?: boolean;
}

interface PolicySearchByNameOptionsProps {
  keywords: string;
  page?: number;
  size?: number;
}

export interface PolicySearchItem {
  name: string;
  id: number;
}

const cleanKeyword = (keyword: string) => {
  return keyword.replace(/[^0-9a-zA-Z가-힣]/g, '');
};

const findMatchingSubstring = (originalText: string, searchKeyword: string) => {
  const cleanedKeyword = cleanKeyword(searchKeyword);

  const mapping: number[] = [];
  let cleanedText = '';

  for (let i = 0; i < originalText.length; i++) {
    const char = originalText[i];
    if (/[0-9a-zA-Z가-힣]/.test(char)) {
      cleanedText += char;
      mapping.push(i);
    }
  }

  const startIndex = cleanedText.indexOf(cleanedKeyword);
  if (startIndex === -1) return '';

  const originalStartIndex = mapping[startIndex];
  const originalEndIndex = mapping[startIndex + cleanedKeyword.length - 1] + 1;

  return originalText.slice(originalStartIndex, originalEndIndex);
};

export const policyQueryOptions = {
  feedback: ({ id, isLogin }: PolicyOptionsProps) =>
    queryOptions({
      queryKey: [
        policyQueryOptionsKeys.feedback,
        queryKeys.like,
        queryKeys.bookmark,
        id,
        isLogin,
      ],
      queryFn: () => {
        const params = { policyId: id };

        return isLogin
          ? AUTH_API.getPolicyFeedback(params)
          : UNAUTH_API.getPolicyFeedback(params);
      },
      staleTime: 0,
      enabled: isLogin !== undefined,
      placeholderData: keepPreviousData,
      select: ({ context }) => ({
        readingCount: context?.reading_count ?? 0,
        hasNewComment: context?.new_comment_yn ?? false,
        isThumbUp: context?.like_yn ?? false,
        thumbUpCount: context?.like_count ?? 0,
        isBookmarked: context?.wishlist_yn,
        bookmarkCount: context?.wishlist_count ?? 0,
        commentCount: context?.comment_count ?? 0,
        likeCount: context?.like_count ?? 0,
      }),
    }),
  count: () =>
    queryOptions({
      queryKey: [queryKeys.policyCount],
      queryFn: UNAUTH_API.getPolicyCount,
    }),
  pick: ({ isLogin, id }: PolicyOptionsProps) =>
    queryOptions({
      queryKey: [queryKeys.pickPolicyList, queryKeys.bookmark, id, isLogin],
      queryFn: () => {
        return isLogin
          ? AUTH_API.getPickPolicyList({ welloPickId: id })
          : UNAUTH_API.getPickPolicyList({ welloPickId: id });
      },
      enabled: isLogin !== undefined,
      select: ({ context }) => ({
        id: context?.wello_pick?.wello_pick_id,
        title: context?.wello_pick?.title ?? '',
        contents: context?.wello_pick?.contents ?? '',
        thumbnail: context?.wello_pick?.file_url ?? '',
        policyCount: context?.wello_pick?.policy_count ?? 0,
        policyList:
          context?.wello_pick_policy_list?.map((metaPolicy) => ({
            id: metaPolicy.id,
            idIdx: metaPolicy.meta_policy_id_idx,
            isThumbUp: metaPolicy.like_yn,
            policyName: metaPolicy.policy_name,
            dday: metaPolicy.dday,
            agencyLogo: metaPolicy.agency_logo,
            agency: metaPolicy.agency,
            dueDateEnd: metaPolicy.expiration_date,
            supportBenefit: metaPolicy.support_benefit,
            provisionTypeListStr: metaPolicy.provision_type_str_list,
            likeCount: metaPolicy.like_count,
            commentCount: metaPolicy.comment_count,
            isWishlist: metaPolicy.wishlist_yn,
          })) ?? [],
      }),
    }),
  policyDetail: (idx: string) =>
    queryOptions({
      queryKey: [policyQueryOptionsKeys.policyDetail, idx],
      queryFn: () => getPolicyDetail(idx),
    }),
  dictionary: () =>
    queryOptions({
      queryKey: [queryKeys.static],
      queryFn: UNAUTH_API.getPolicyDictionaryList,
      select: ({ context }) => context?.policy_dictionary_list,
      staleTime: Infinity,
      gcTime: Infinity,
    }),
  search: ({
    isLogin,
    keyword,
    codeRegion,
    codeSubRegion,
    descAgeList,
    codeProvisionTypeList,
    policySort = 'LATEST',
  }: SearchOptionsProps) => {
    const INITIAL_PAGE = 1;
    const PAGE_SIZE = 20;

    return infiniteQueryOptions({
      queryKey: [
        queryKeys.recommendPolicyList,
        queryKeys.bookmark,
        keyword,
        codeRegion,
        codeSubRegion,
        descAgeList ?? [],
        codeProvisionTypeList ?? [],
        policySort,
        INITIAL_PAGE,
        PAGE_SIZE,
        isLogin,
      ],
      queryFn: ({ pageParam = INITIAL_PAGE }) => {
        const params = {
          searchKeyword: keyword,
          codeRegion,
          codeSubRegion,
          page: pageParam,
          size: PAGE_SIZE,
          policySort,
          descAgeList,
          codeProvisionTypeList,
        };

        return isLogin
          ? AUTH_API.getSearchRecommendPolicyList(params)
          : UNAUTH_API.getSearchRecommendPolicyList(params);
      },
      enabled: isLogin !== undefined,
      getNextPageParam: (lastPage) => {
        const { page: currentPage, total_count: totalCount } =
          lastPage.context ?? {};

        if (!totalCount || !currentPage) return;

        const nextPage = currentPage + 1;
        const totalPageCount = Math.ceil(totalCount / PAGE_SIZE);

        if (nextPage <= totalPageCount) return nextPage;
      },
      initialPageParam: INITIAL_PAGE,
      placeholderData: keepPreviousData,
    });
  },
  policySearchByName: ({
    keywords,
    page = 1,
    size = 20,
  }: PolicySearchByNameOptionsProps) =>
    infiniteQueryOptions({
      queryKey: [keywords, size, page],
      queryFn: ({ pageParam }) => {
        return UNAUTH_API.getSearchPolicyListByPolicyName({
          page: pageParam,
          size,
          policyName: keywords,
        });
      },
      placeholderData: keepPreviousData,
      initialPageParam: page,
      getNextPageParam: ({ context }) => {
        const { has_next, page } = context ?? {};

        if (!has_next || !page) return;

        return page + 1;
      },
      enabled: !!keywords,
      select: ({ pages }) =>
        pages.flatMap(
          ({ context }) =>
            context?.contents?.map((item) => ({
              id: item.policy_id,
              name: item.policy_name,
              logo: item.agency_logo,
              matchedText: findMatchingSubstring(item.policy_name, keywords),
            })) || [],
        ),
    }),
  interest: ({ provisionType }: InterestOptionsProps) =>
    queryOptions({
      queryKey: [queryKeys.interestPolicy, queryKeys.like, provisionType],
      queryFn: () => {
        return AUTH_API.getInterestPolicyList({
          codeProvisionType: provisionType,
        });
      },
      placeholderData: keepPreviousData,
    }),
  recent: () =>
    queryOptions({
      queryKey: [queryKeys.recentPolicy, queryKeys.like],
      staleTime: 0,
      queryFn: () => {
        return AUTH_API.getRecentPolicyList();
      },
    }),
  peerGroupPolicy: ({ isLogin, memberJoinStatus }: PolicyPeerGroupOptions) =>
    queryOptions({
      queryKey: [
        queryKeys.like,
        policyQueryOptionsKeys.peerGroupPolicy,
        memberJoinStatus,
        isLogin,
      ],
      queryFn: async () => {
        const res = await AUTH_API.getPeerGroupPolicy();

        const policyList = await Promise.all(
          res.context?.policy_list?.map(async (policy) => {
            const feedback = await AUTH_API.getPolicyFeedback({
              policyId: policy.id,
            });

            return {
              ...policy,
              like_yn: feedback.context?.like_yn ?? false,
              like_count: feedback.context?.like_count ?? 0,
            };
          }) ?? [],
        );

        return policyList;
      },
      enabled:
        memberJoinStatus === MEMBER_JOIN_STATUS.COMPLETE ||
        memberJoinStatus === MEMBER_JOIN_STATUS.EMPTY_FILTER,
    }),
  recentSimilar: () =>
    queryOptions({
      queryKey: [queryKeys.recentSimilarPolicy, queryKeys.like],
      queryFn: () => {
        return AUTH_API.getRecentSimilarPolicy();
      },
    }),
  hometownPopular: () =>
    queryOptions({
      queryKey: [queryKeys.like, queryKeys.hometownPopular],
      queryFn: () => {
        return AUTH_API.getHometownPopularPolicy();
      },
    }),
  relatedCommunityList: ({ policyId }: { policyId: number }) =>
    queryOptions({
      queryKey: [
        policyQueryOptionsKeys.relatedCommunityList,
        policyId,
        queryKeys.like,
      ],
      queryFn: () => UNAUTH_API.getPolicyRelatedCommunityList({ policyId }),
      select: ({ context }) => context?.policy_related_community_list,
    }),
  relatedMetaPolicyList: ({
    isLogin,
    metaPolicyIdIdx,
  }: RelatedMetaPolicyListProps) => {
    return queryOptions({
      queryKey: [
        policyQueryOptionsKeys.relatedMetaPolicyList,
        queryKeys.like,
        isLogin,
        metaPolicyIdIdx,
      ],
      placeholderData: keepPreviousData,
      queryFn: () => {
        return isLogin
          ? AUTH_API.getRelatedMetaPolicyList({ metaPolicyIdIdx })
          : UNAUTH_API.getRelatedMetaPolicyList({ metaPolicyIdIdx });
      },
    });
  },
  listChildcareRelatedPolicy: ({
    subRegionCode,
    isLogin,
    isOnlyLogin,
  }: ListChildcareRelatedPolicyProps) => {
    return queryOptions({
      queryKey: [
        policyQueryOptionsKeys.listChildcareRelatedPolicy,
        queryKeys.like,
        subRegionCode,
        isLogin,
        isOnlyLogin,
      ],
      enabled: isOnlyLogin ? !!isLogin : isLogin !== undefined,
      queryFn: () => {
        return isLogin
          ? AUTH_API.getListChildcareRelatedPolicy({ subRegionCode })
          : UNAUTH_API.getListChildcareRelatedPolicy({ subRegionCode });
      },
    });
  },
  policyPick: () =>
    queryOptions({
      queryKey: [policyQueryOptionsKeys.policyPick],
      queryFn: UNAUTH_API.getWelloPick,
      select: ({ context }) => context?.wello_pick_banner_list,
    }),
};
