import type { COMMENT_TYPE } from './types';
import type { WelloCommentDto } from '@generatedapi/auth';
import type {
  ListWelloCommentResponse,
  ListWelloCommentDto,
} from '@generatedapi/unauth';

import { COMMENT_SORT_TYPE, COMMENT_STATUS } from './types';

import { UNAUTH_API } from '.';

export interface CommentData {
  commentList: Comment[];
  totalCount: number;
  totalRootCount: number;
  visibleTotalCount: number;
}

export const normalizedComment = (
  comment: WelloCommentDto,
  userId?: number,
) => ({
  contentId: comment.content_id,
  parentNickName: comment.parent_nick_name,
  commentId: comment.wello_comment_id,
  commentIdIdx: comment.content_id_idx,
  createdAt: comment.date_time,
  commentType: comment.comment_type_value,
  isBlocked: comment.block_yn,
  isDisplay:
    comment.comment_status_cd === COMMENT_STATUS.NON_DISPLAY ? false : true,
  isReport: comment.report_yn,
  commentTypeCd: comment.comment_type_cd,
  thumbUpCount: comment.like_count,
  isDeleted: comment.comment_status_cd === COMMENT_STATUS.DELETED,
  contents: comment.contents,
  nickname: comment.nick_name,
  profileImage: comment.profile_image,
  isMine: comment.create_by === userId,
  isMyThumbUp: comment.like_yn,
  parentCommentId: comment.parent_wello_comment_id,
  rootCommentId: comment.root_wello_comment_id,
  isBest: comment.best_yn,
  isPicked: comment.comment_pick_yn,
  isWelloHelper: comment.wello_helper_yn,
  relationPolicyList: comment.relation_policy_list,
  hasRelativePolicy: !!comment.relation_policy_list?.length,
});

type NormalizedComment = ReturnType<typeof normalizedComment>;

export interface Comment extends NormalizedComment {
  childCommentList?: NormalizedComment[];
}

export const selectCommentList = (
  context?: Partial<ListWelloCommentResponse>,
  userId?: number,
): CommentData => {
  if (!context || !context.contents) {
    return {
      commentList: [],
      totalCount: 0,
      totalRootCount: 0,
      visibleTotalCount: 0,
    };
  }
  const classifyCommentList = (commentList: ListWelloCommentDto[]) =>
    commentList.reduce((acc, comment) => {
      const transformedComment = normalizedComment(comment, userId);

      if (!comment.root_wello_comment_id) {
        acc.push({ ...transformedComment, childCommentList: [] });
      } else {
        const rootComment = acc.find(
          ({ commentId }) => commentId === comment.root_wello_comment_id,
        );
        rootComment?.childCommentList?.push(transformedComment);
      }

      return acc;
    }, [] as Comment[]);

  const commentList = classifyCommentList(context.contents);

  const specificCommentList = context.specific_comment
    ? classifyCommentList(
        context.specific_comment.map(
          ({ parent_nick_name = '', ...commonValues }) => ({
            comment_count: 0,
            parent_nick_name,
            ...commonValues,
          }),
        ),
      )
    : [];

  return {
    commentList: specificCommentList.concat(commentList),
    totalRootCount: context.root_total_count ?? 0,
    totalCount: context.total_count ?? 0,
    visibleTotalCount: context.visible_total_count ?? 0,
  };
};

interface GetCommentListProps {
  id: number;
  commentType: COMMENT_TYPE;
  sortType?: COMMENT_SORT_TYPE;
}

export const getCommentList = async ({
  commentType,
  id,
}: GetCommentListProps) => {
  const { context } = await UNAUTH_API.getCommentList({
    page: 1,
    size: 100,
    commentTypeCd: commentType,
    contentId: id,
    commentSort: COMMENT_SORT_TYPE.LATEST,
  });

  return selectCommentList(context);
};
