import { createUuid } from '@common/utils';

export const queryKeys = {
  latestPolicyList: createUuid(),
  recommendPolicyList: createUuid(),
  recommendNewPolicyList: createUuid(),
  recommendNewPolicyProvisionTypeList: createUuid(),
  recommendNearExpiryPolicyList: createUuid(),
  recommendNearExpiryPolicyProvisionTypeList: createUuid(),
  recommendTrendingPolicyList: createUuid(),
  recommendTrendingPolicyProvisionTypeList: createUuid(),
  recommendUnreadPolicyList: createUuid(),
  recommendUnreadPolicyProvisionTypeList: createUuid(),
  policyCodeList: createUuid(),
  policyInfoFeedback: createUuid(),
  interestPolicy: createUuid(),
  recentPolicy: createUuid(),
  recentSimilarPolicy: createUuid(),
  hometownNewsList: createUuid(),
  hometownNewsFeedback: createUuid(),
  hometownPopular: createUuid(),
  myGeolocation: createUuid(),
  profileScrap: createUuid(),
  familyList: createUuid(),
  inquiryList: createUuid(),
  inquiry: createUuid(),
  pickPolicyList: createUuid(),
  situationPolicyList: createUuid(),
  mainPage: createUuid(),
  liveComment: createUuid(),
  myCustomPolicyFilter: createUuid(),
  myInterestedPolicyFilter: createUuid(),
  appPushList: createUuid(),
  commentList: createUuid(),
  policyCount: createUuid(),
  like: createUuid(),
  bookmark: createUuid(),
  static: 'STATIC',
  popularHometownNews: createUuid(),
};
