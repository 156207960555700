import { CustomError } from '@common/utils';
import { withTimeout } from 'es-toolkit';

import { GPT_PROMPT_TYPE, UNAUTH_API } from '.';

export const getPolicyDetail = async (metaPolicyIdIdx: string) => {
  const { context } = await UNAUTH_API.getPolicyDetail({
    metaPolicyIdIdx,
  });

  if (!context)
    throw new CustomError({
      return_message: '정책 상세 정보를 불러오는데 실패했습니다.',
    });

  let relatedInfo = '';

  if (context.apply_documents)
    relatedInfo += `○ 구비서류\n - ${context.apply_documents
      ?.replaceAll('\n', '\n  - ')
      .replaceAll('||', '\n  - ')}}`;

  if (context.base_rule)
    relatedInfo += `○ 관계법령\n - ${context.base_rule
      ?.replaceAll('\n', '\n  - ')
      .replaceAll('||', '\n  - ')}`;

  if (!relatedInfo) relatedInfo = '- 관련 정보 없음';

  const [summaryString, keywordString] = await withTimeout(async () => {
    let summaryString = context.ai_summary;
    let keywordString = context.ai_seo_keyword;

    [summaryString, keywordString] = await Promise.all([
      summaryString
        ? summaryString
        : UNAUTH_API.updatePolicyAiInfo({
            metaPolicyIdIdx,
            requestBody: {
              gpt_prompt_type: GPT_PROMPT_TYPE.SUMMARY,
            },
          }).then((res) => res.context?.result),
      keywordString
        ? keywordString
        : UNAUTH_API.updatePolicyAiInfo({
            metaPolicyIdIdx,
            requestBody: {
              gpt_prompt_type: GPT_PROMPT_TYPE.KEYWORD,
            },
          }).then((res) => res.context?.result),
    ]);

    return [summaryString, keywordString];
  }, 3_000).catch(() => []);

  const aiSummaryList = summaryString
    ?.split('***')
    .map((s) => s.trim())
    .slice(1);

  const aiKeywordList = keywordString
    ?.split(' | ')
    .map((s) => s.trim())
    //! 한자 제외
    .filter((keyword) => !/[\u4E00-\u9FFF]/.test(keyword));

  return {
    id: context.id,
    idx: context.meta_policy_id_idx,
    policyName: context.policy_name,
    descAgeList: context.desc_age_list,
    dday: context.dday,
    policyApplyId: context.wello_policy_apply_id,
    provisionTypeList: context.provision_type_list,
    applyTypeList: context.apply_type_list,
    agencyLogo: context.agency_logo,
    agency: context.agency,
    dueDateBetween: context.due_date_between,
    supportBenefit: context.support_benefit,
    descTarget: context.desc_target,
    descSupport: context.desc_support,
    descProvision: context.desc_provision,
    relatedInfo,
    policyBannerTypeList: context.policy_banner_type_list,
    descApply: context.desc_apply,
    agencyTel: context.agency_tel,
    infoUrl: context.info_url,
    aiSummaryList,
    aiKeywordList,
  };
};

export type PolicyDetail = Awaited<ReturnType<typeof getPolicyDetail>>;
