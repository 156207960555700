import { createUuid } from '@common/utils';
import { queryOptions } from '@tanstack/react-query';

import { AUTH_API } from '@/api';
import { queryKeys } from '@/constants/query-keys';

interface ProfileScrapOptionsProps {
  isLogin: boolean;
}

export const profileScrapQueryOptionsKeys = {
  policyScrap: createUuid(),
  communityScrap: createUuid(),
  hometownNewsScrap: createUuid(),
  bestContentsScrap: createUuid(),
  voucherProviderScrap: createUuid(),
  emergencyRoomScrap: createUuid(),
};

export const profileScrapQueryOptions = {
  policy: ({ isLogin }: ProfileScrapOptionsProps) =>
    queryOptions({
      queryKey: [
        profileScrapQueryOptionsKeys.policyScrap,
        queryKeys.profileScrap,
        queryKeys.like,
        queryKeys.bookmark,
      ],
      queryFn: AUTH_API.getPolicyWishlist,
      enabled: isLogin,
      select: ({ context }) => context,
    }),

  community: () =>
    queryOptions({
      queryFn: () => AUTH_API.getCommunityWishlist(),
      queryKey: [
        profileScrapQueryOptionsKeys.communityScrap,
        queryKeys.profileScrap,
        queryKeys.like,
        queryKeys.bookmark,
      ],
      select: ({ context }) => context,
    }),
  hometownNews: ({ isLogin }: ProfileScrapOptionsProps) =>
    queryOptions({
      queryKey: [
        queryKeys.profileScrap,
        queryKeys.like,
        queryKeys.bookmark,
        profileScrapQueryOptionsKeys.hometownNewsScrap,
      ],
      queryFn: AUTH_API.getHometownNewsWishlist,
      enabled: isLogin,
      select: ({ context }) => context,
    }),
  bestContents: ({ isLogin }: ProfileScrapOptionsProps) =>
    queryOptions({
      queryKey: [
        queryKeys.profileScrap,
        queryKeys.like,
        queryKeys.bookmark,
        profileScrapQueryOptionsKeys.bestContentsScrap,
      ],
      queryFn: AUTH_API.getBestContentsWishlist,
      enabled: isLogin,
      select: ({ context }) => context,
    }),
  voucherProvider: ({ isLogin }: ProfileScrapOptionsProps) =>
    queryOptions({
      queryKey: [
        queryKeys.profileScrap,
        queryKeys.bookmark,
        profileScrapQueryOptionsKeys.voucherProviderScrap,
      ],
      queryFn: AUTH_API.getVoucherProviderWishlist,
      enabled: isLogin,
      select: ({ context }) => context,
    }),
  emergencyRoom: ({ isLogin }: ProfileScrapOptionsProps) =>
    queryOptions({
      queryKey: [
        queryKeys.profileScrap,
        queryKeys.bookmark,
        profileScrapQueryOptionsKeys.emergencyRoomScrap,
      ],
      queryFn: AUTH_API.getEmergencyRoomWishlist,
      enabled: isLogin,
      select: ({ context }) => context,
    }),
};
