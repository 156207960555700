import type { COMMENT_TYPE, COMMENT_SORT_TYPE } from '@/api';
import type { Comment } from '@/api/getCommentList';

import { CustomError, createUuid } from '@common/utils';
import {
  infiniteQueryOptions,
  keepPreviousData,
  queryOptions,
} from '@tanstack/react-query';

import { AUTH_API, UNAUTH_API } from '@/api';
import { normalizedComment, selectCommentList } from '@/api/getCommentList';
import { queryKeys } from '@/constants/query-keys';

export const commentQueryOptionsKeys = {
  list: createUuid(),
  myInfinityList: createUuid(),
  commentDetail: createUuid(),
};

interface GetCommentListProps {
  id: number;
  commentType: COMMENT_TYPE;
  sortType?: COMMENT_SORT_TYPE;
}

interface CommentListOptionsProps extends GetCommentListProps {
  isLogin: boolean | undefined;
  userId: number | undefined;
  sortType?: COMMENT_SORT_TYPE;
}

interface CommentListInfinityOptionsProps extends CommentListOptionsProps {
  page?: number;
  size?: number;
  parentCommentId?: number;
}

interface LiveCommentOptionsProps {
  isLogin: boolean | undefined;
  commentTypeCd?: `${COMMENT_TYPE}`;
}

interface MyCommentOptionsProps {
  sortType?: COMMENT_SORT_TYPE;
  userId?: number;
  isLogin: boolean;
}

interface MyInfinityListOptionsProps {
  commentSort?: COMMENT_SORT_TYPE;
  isLogin?: boolean;
}

export const commentQueryOptions = {
  //! 삭제 예정
  list: (
    {
      sortType,
      commentType,
      id,
      isLogin,
      userId,
      page = 1,
      size = 100,
      parentCommentId,
    }: CommentListInfinityOptionsProps,
    onlyAuthUpdate?: boolean,
  ) =>
    queryOptions({
      queryKey: [
        commentQueryOptionsKeys.list,
        queryKeys.like,
        id,
        parentCommentId,
        commentType,
        sortType,
        isLogin,
        page,
        size,
      ],
      staleTime: 0,
      queryFn: () => {
        const params = {
          page,
          size,
          commentTypeCd: commentType,
          contentId: id,
          commentSort: sortType,
          parentCommentId,
        };

        return isLogin
          ? AUTH_API.getCommentList(params)
          : UNAUTH_API.getCommentList(params);
      },
      enabled: onlyAuthUpdate ? isLogin : isLogin !== undefined,
      placeholderData: keepPreviousData,
      select: ({ context }) => {
        if (!context) throw new CustomError();

        return selectCommentList(context, userId);
      },
    }),
  commentInfinityList: ({
    sortType,
    commentType,
    id,
    isLogin,
    userId,
    page = 1,
    size = 50,
    parentCommentId,
  }: CommentListInfinityOptionsProps) => {
    return infiniteQueryOptions({
      queryKey: [
        commentQueryOptionsKeys.list,
        queryKeys.commentList,
        queryKeys.like,
        queryKeys.bookmark,
        isLogin,
        size,
        commentType,
        id,
        sortType,
        parentCommentId,
      ],
      enabled: isLogin !== undefined,
      queryFn: ({ pageParam }) => {
        const params = {
          page: pageParam,
          size,
          commentTypeCd: commentType,
          contentId: id,
          commentSort: sortType,
          parentCommentId,
        };

        return isLogin
          ? AUTH_API.getCommentList(params)
          : UNAUTH_API.getCommentList(params);
      },
      getNextPageParam: ({ context }) => {
        const { root_total_count, page } = context ?? {};

        if (!root_total_count || !page) return;

        const nextPage = page + 1;
        const maxPage = Math.ceil(root_total_count / size);

        if (maxPage < nextPage) return;

        return nextPage;
      },
      initialPageParam: page,
      select: ({ pages }) => {
        const lastCommentPage = selectCommentList(
          pages[pages.length - 1].context,
          userId,
        );

        const flatCommentList = pages.flatMap(
          ({ context }) => selectCommentList(context, userId).commentList,
        );

        const uniqueCommentList = flatCommentList.filter(
          (comment, index, self) =>
            index === self.findIndex((t) => t.commentId === comment.commentId),
        );

        return {
          ...lastCommentPage,
          commentList: uniqueCommentList,
        };
      },
    });
  },
  liveList: ({ commentTypeCd, isLogin }: LiveCommentOptionsProps) =>
    queryOptions({
      queryKey: [queryKeys.liveComment, commentTypeCd, isLogin],
      queryFn: () => {
        return isLogin
          ? AUTH_API.getLiveCommentList({ commentTypeCd })
          : UNAUTH_API.getLiveCommentList({ commentTypeCd });
      },
      enabled: isLogin !== undefined,
      select: ({ context }) => {
        return {
          liveWelloCommentList: context?.live_wello_comment_list?.map(
            (liveComment) => {
              return {
                id: liveComment.content_id,
                idIdx: liveComment.content_id_idx,
                title: liveComment.title,
                contents: liveComment.contents,
                isBest: liveComment.best_yn,
                commentId: liveComment.wello_comment_id,
                commentCount: liveComment.comment_count,
                isMyThumbUp: liveComment.like_yn,
                commentTypeCd: liveComment.comment_type_cd,
                commentType: liveComment.comment_type_value,
                nickName: liveComment.nick_name,
                profileImage: liveComment.profile_image,
                thumbUpCount: liveComment.like_count,
                dateTime: liveComment.date_time,
              } satisfies LiveCommentQueryProps;
            },
          ),
        };
      },
    }),
  commentDetail: (commentId: number) =>
    queryOptions({
      queryKey: [commentQueryOptionsKeys.commentDetail, commentId],
      queryFn: () =>
        UNAUTH_API.getCommentDetail({
          welloCommentId: commentId,
        }),
    }),
  myList: ({ sortType, userId, isLogin }: MyCommentOptionsProps) =>
    queryOptions({
      queryKey: [queryKeys.commentList, queryKeys.like, sortType],
      queryFn: () => {
        const params = {
          page: 1,
          size: 100,
          commentSort: sortType,
        };

        return AUTH_API.getMyCommentList(params);
      },
      enabled: isLogin,
      placeholderData: keepPreviousData,
      select: ({ context }) => {
        if (!context || !context.contents) {
          return {
            commentList: undefined,
            totalCount: 0,
            totalRootCount: 0,
            visibleTotalCount: 0,
          };
        }

        const initialCommentList: Comment[] = [];

        const commentList = context.contents.reduce((acc, comment) => {
          const transformedComment = normalizedComment(comment, userId);

          if (!comment.root_wello_comment_id) {
            acc.push({ ...transformedComment, childCommentList: [] });
          } else {
            const rootComment = acc.find(
              ({ commentId }) => commentId === comment.root_wello_comment_id,
            );
            rootComment?.childCommentList?.push(transformedComment);
          }

          return acc;
        }, initialCommentList);

        return {
          commentList,
          totalCount: context.total_count,
        };
      },
    }),
  myInfinityList: ({ commentSort, isLogin }: MyInfinityListOptionsProps) => {
    const DEFAULT_SIZE = 20;
    const DEFAULT_PAGE = 1;

    return infiniteQueryOptions({
      queryKey: [
        commentQueryOptionsKeys.myInfinityList,
        queryKeys.like,
        DEFAULT_SIZE,
        DEFAULT_PAGE,
        commentSort,
      ],
      enabled: isLogin !== undefined,
      queryFn: ({ pageParam }) => {
        const params = {
          page: pageParam,
          size: DEFAULT_SIZE,
          commentSort,
        };

        return AUTH_API.getMyCommentList(params);
      },
      getNextPageParam: (page) => {
        if (page.context?.total_count && page.context.page) {
          const nextPage = page.context.page + 1;
          const maxPage = Math.ceil(page.context.total_count / DEFAULT_SIZE);

          return nextPage <= maxPage ? nextPage : undefined;
        }
      },
      initialPageParam: DEFAULT_PAGE,
      placeholderData: keepPreviousData,
    });
  },
};

export interface LiveCommentQueryProps {
  id?: number;
  idIdx?: string;
  title?: string;
  nickName?: string;
  contents?: string;
  commentTypeCd?: string;
  isBest?: boolean;
  isLoading?: boolean;
  isMyThumbUp?: boolean;
  commentId?: number;
  commentType?: string;
  thumbUpCount?: number;
  dateTime?: string;
  commentCount?: number;
  isDeleted?: boolean;
  profileImage?: string;
  href?: string;
  onClickThumbUp?: VoidFunction;
  onClickComment?: VoidFunction;
  onClickDeleteButton?: VoidFunction;
}
